const hero = document.querySelector('.hero');
const heroPicture = hero.querySelector('.hero__picture');
const heroImage = hero.querySelector('.hero__image_type_desktop');
const heroSubheading = hero.querySelector('.hero__subheading');

const objectPink = heroImage.querySelector('#object-pink');
const objectYellow = heroImage.querySelector('#object-yellow');
const objectBrown = heroImage.querySelector('#object-brown');
const objectOrange = heroImage.querySelector('#object-orange');
const objectGreen = heroImage.querySelector('#object-green');

let isHeroInitialized = false;
let cleanupHero;

function initHero() {
  const startImageHeight = heroPicture.getBoundingClientRect().height;
  const startImageOffsetTop = heroPicture.offsetTop;

  const current = {
    heroTop: 0,
    pictureHeight: startImageHeight,
    imageHeight: startImageHeight,
    subheadingScale: 1,
    subheadingOpacity: 1,
  }

  hero.classList.add('hero_animated');
  heroPicture.classList.add('hero__picture_animated');
  heroImage.classList.add('hero__image_animated');
  heroSubheading.classList.add('hero__subheading_animated');

  heroPicture.style.setProperty('--height', `${current.pictureHeight}px`);
  heroImage.style.setProperty('--height', `${current.imageHeight}px`);

  function updateHeroTop(offsetTop) {
    const newHeroTop = Math.min(offsetTop, (startImageOffsetTop + startImageHeight));

    if (newHeroTop !== current.heroTop) {
      current.heroTop = newHeroTop;
      hero.style.setProperty('--top', `${newHeroTop}px`);
    }
  }

  function updatePictureHeight(offsetTop) {
    const newPictureHeight = offsetTop < startImageOffsetTop
    ? startImageHeight
    : Math.max(startImageHeight - (offsetTop - startImageOffsetTop), 0);

    if (newPictureHeight !== current.pictureHeight) {
      current.pictureHeight = newPictureHeight;
      heroPicture.style.setProperty('--height', `${newPictureHeight}px`);
    }
  }

  function updateImage(offsetTop) {
    const newImageHeight = startImageHeight
      + (offsetTop < startImageOffsetTop ? offsetTop : startImageOffsetTop);

    if (newImageHeight !== current.imageHeight) {
      current.imageHeight = newImageHeight;
      heroImage.style.setProperty('--height', `${newImageHeight}px`);

      objectBrown.style.setProperty('transform', `translateX(${(offsetTop / 20) * -1}px)`);
      objectOrange.style.setProperty('transform', `translateX(${(offsetTop / 12) * -1}px)`);
      objectPink.style.setProperty('transform', `translateX(${(offsetTop / 10)}px)`);
      objectYellow.style.setProperty('transform', `translateX(${(offsetTop / 12) * -1}px)`);
    }
  }

  function updateSubheading(offsetTop) {
    const newSubheadingScale = offsetTop < (startImageOffsetTop - 150)
    ? 1
    : Math.max(1 - (offsetTop - (startImageOffsetTop - 150)) / (startImageHeight * 1.6), 0.7);
    const newSubheadingOpacity = offsetTop < (startImageOffsetTop - 150)
    ? 1
    : Math.max(1 - (offsetTop - (startImageOffsetTop - 150)) / (startImageHeight * 0.5), 0);

    if (newSubheadingScale !== current.subheadingScale || newSubheadingOpacity !== current.subheadingOpacity) {
      current.subheadingScale = newSubheadingScale;
      current.subheadingOpacity = newSubheadingOpacity;
      heroSubheading.style.setProperty('--scale', `${newSubheadingScale}`);
      heroSubheading.style.setProperty('--opacity', `${newSubheadingOpacity}`);
    }
  }

  function updateHero(offsetTop) {
    updateHeroTop(offsetTop);
    updatePictureHeight(offsetTop);
    updateImage(offsetTop);
    updateSubheading(offsetTop);
  }

  updateHero(0);

  function handleScroll() {
    if (isHeroInitialized) {
      updateHero(window.scrollY);
    }
  }

  window.addEventListener('scroll', handleScroll);

  isHeroInitialized = true;

  function cleanupHero() {
    hero.classList.remove('hero_animated');
    heroPicture.classList.remove('hero__picture_animated');
    heroImage.classList.remove('hero__image_animated');
    heroSubheading.classList.remove('hero__subheading_animated');

    hero.style.removeProperty('--top');
    heroPicture.style.removeProperty('--height');
    heroImage.style.removeProperty('--height');
    heroSubheading.style.removeProperty('--scale');
    heroSubheading.style.removeProperty('--opacity');

    window.removeEventListener('scroll', handleScroll);

    isHeroInitialized = false;
  }

  return cleanupHero;
}

function handleResize() {
  if (window.matchMedia('(min-width: 450px)').matches) {
    if (!isHeroInitialized) {
      if (heroImage.complete) {
        cleanupHero = initHero();
      } else {
        heroImage.addEventListener('load', () => {
          cleanupHero = initHero();
        });
      }
    }
  } else {
    if (isHeroInitialized && typeof cleanupHero === 'function') {
      cleanupHero();
    }
  }
}


if (window.matchMedia('(min-width: 450px)').matches) {
  cleanupHero = initHero();
}

window.addEventListener('resize', handleResize);
